.header {
  display: flex;
  justify-content: space-between;
}

.tabMenu {
  width: 100%;
}

.home {
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-bottom: 2px solid var(--surface-d);
}
